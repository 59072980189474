var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "exampleInputGroup1",
                label: "Email адрес:",
                "label-for": "exampleInput1"
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "exampleInput1",
                  type: "email",
                  required: "",
                  placeholder: "Введите Вашу почту"
                },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _c("b-col", [
                _c("label", { attrs: { for: "input-text" } }, [_vm._v("Text:")])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "my-2" },
            [
              _c(
                "b-col",
                [
                  _c("b-form-textarea", {
                    attrs: {
                      rows: "3",
                      "max-rows": "6",
                      id: "input-text",
                      type: "text",
                      placeholder: "Введите Ваше сообщение"
                    },
                    model: {
                      value: _vm.form.text,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "text", $$v)
                      },
                      expression: "form.text"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("b-button", { attrs: { type: "submit", variant: "primary" } }, [
            _vm._v("Отправить")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }