<template>
    <div>
        <b-alert variant="success" 
                dismissible
                :show="showDismissibleSuccess"
                @dismissed="showDismissibleSuccess=0"
                @dismiss-count-down="countDownChanged"
        >{{ succesInfo }}
        </b-alert>
        <b-alert variant="danger"
                :show="showDismissibleAlert"
                @dismissed="showDismissibleAlert=0"
                @dismiss-count-down="countDownChangedError"
                >{{ alertInfo }}
        </b-alert>
    </div>
</template>

<script>
export default {
    data(){
        return{
            showDismissibleSuccess: 0, // отвечает за показ сообщения о событии
            showDismissibleAlert: 0, // отвечает за показ сообщения об ошибке
            dismissSecs: 5 // время показа ошибки или сообщения о событии
        }
    }, 
    computed:{
            // выводит общую информацию
        succesInfo: function(){
            if (this.$store.getters.getEventInfo){
                this.showDismissibleSuccess = this.dismissSecs
                return this.$store.getters.getEventInfo
            }
        },
            // выводит ошибки
        alertInfo: function(){
            if (this.$store.getters.getErrorInfo){
                this.showDismissibleAlert = this.dismissSecs
                return this.$store.getters.getErrorInfo
            }
        }
    },
    methods:{
            // закрывает окно общей информации
        countDownChanged (dismissCountDown) {
            this.showDismissibleSuccess = dismissCountDown
        },
        // закрывает окно ошибок
        countDownChangedError (dismissCountDownError) {
            this.showDismissibleAlert = dismissCountDownError
        }
    }
}
</script>

