<template>
    <div>
        <b-row>
            <b-col>
                <b-button variant="success" v-on:click="bitrixDelete" class="mb-1">Удалить хранилища Битрикса</b-button>
                <!-- <b-button variant="success" v-on:click="seenecoAcc" class="mb-1">Счета сенеко</b-button> -->
            </b-col>
            <b-col>
                <b-form-group horizontal label="Кол-во операций на странице" class="mb-1">
                    <b-form-select :options="numberOfСolumns"  v-model="perPage" @input="changeNumberofPage">
                    <template slot="first">
                        <option :value="perPage">{{getPages}}</option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
    
</template>

<script>

import axios from 'axios'

export default {
    data(){
        return {
            numberOfСolumns: [],
            perPage: ''
            // summa: []
        }
    },
    mounted(){
        this.$store.dispatch("numberPages/numberOfPages");         
    },
    computed: {
        getPages: function(){
            let changeNumber = [10,25,50]
            if (this.$store.getters['numberPages/getnumberOfPages']){
                let pageNumber = this.$store.getters['numberPages/getnumberOfPages']
                let changeNumberfilter = changeNumber.filter(function(value){
                    return value != pageNumber                    
                })
                this.numberOfСolumns = changeNumberfilter
                return pageNumber
            }
        }
    },
    methods:{
        // удаление хранилища Битрикс24
        bitrixDelete: function(){
            console.log(1)
            axios
            .get("https://"+obj['domain']+"/rest/entity.delete?auth="+obj['auth']+"&ENTITY=store")
            .then(response => {
                console.log("Хранилище Б24 удалено")
                })
            .catch(error => {
                if (error == 'Error: Request failed with status code 400'){
                    console.log("Хранилища не удалено, возможно его и не было")
                }   else {
                    console.error(error)
                }
            });
            console.log(2)
            axios
            .get("https://"+obj['domain']+"/rest/entity.delete?auth="+obj['auth']+"&ENTITY=settings")
            .then(response => {
                console.log("Хранилище Б24 удалено")
                })
            .catch(error => {
                if (error == 'Error: Request failed with status code 400'){
                    console.log("Хранилища не удалено, возможно его и не было")
                }   else {
                    console.error(error)
                }
            });
        },
        seenecoAcc: function(){
            axios
                .get("https://"+obj['domain']+"/rest/entity.item.get?ENTITY=settings&auth="+obj['auth'])
                .then(response =>{
                    let seenecoKey = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9eyJzdWIiOiJtaWhhaWxAZGVtZXNoa28ucnUiLCJpc3MiOiJodHRwczovL3NiYmZtLnJ1IiwiYXBpX3Vzcl9pZCI6MjY1NDczLCJleHAiOjE1NjQxNTI0ODAsImlhdCI6MTUzMjYxNjQ4MH0.s7bU7rRyjog0NhbU9labvhvkCAuTHEUC6M2IGfShubc'
                    // JSON.parse(response.data.result[0]['DETAIL_TEXT'])
                    seenecoGet(seenecoKey)       
                })
                .catch(error => console.log(error));

            function seenecoGet(key){
                let finalGetProxy = "?adress=https://seeneco.com/snc-core-services/api/v2/cash/accounts&auth="+key;
                axios
                    .get("/server.php" + finalGetProxy)
                    .then(response =>{
                        console.log(response.data)              
                    })
                    .catch(error => console.log(error));
            }
            
        },
        changeNumberofPage: function(){
                let change = this.perPage
                this.$store.dispatch("numberPages/changeNumberOfPages", change)
        } 
    }
}
</script>

<style>

</style>
