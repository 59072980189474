import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

import accountTie from "./modules/accountTie.js"
import numberPages from "./modules/numberPages.js"
import import1C from "./modules/numberPages.js"
import accountEdit from "./modules/accountEdit.js"

const store = new Vuex.Store({
    namespaced: true,
    modules:{
        accountTie,
        numberPages,
        import1C,
        accountEdit
    },
    state: {
        accountStore: '', // данные для вывода в таблицу
        periodData: {}, // дата
        accountInfo: [], // счета разделы из хранидища битрикс
        IdSections: [], // id разделов (счетов)
        import1CStore: [], // данные из импортированного файла 1С 
        error: '', // сообщение об ошибке
        eventInfo: '', // сообщение об операции
        periodData1CStore:'', // дата оперций из файла импорта 1С
        BillsB24: [], // счета из Б24
        balanceStore: [], // текущий остаток на счете
        disable: false,
        admin: false // является ли пользователь админом
    },
    getters: {
        getAccountStore(state) {
            return state.accountStore;
        },
        getPeriodData(state) {
            return state.periodData;
        },
        getAccountInfo(state){
            return state.accountInfo;
        },
        getEventInfo(state){
            return state.eventInfo;
        },
        getErrorInfo(state){
            return state.error;
        },
        getBillsB24foTable(state){
            return state.BillsB24;
        },
        getBalanceData(state){
            return state.balanceStore;
        }, 
        getAdmin(state){
            return state.admin;
        }
    },
    mutations: {
        loadAccount(state, payload){
            state.accountStore = payload;
        },
        loadPeriodData(state, payload){
            state.periodData = payload;
        },
        accounsInformationMut(state, payload){
            state.accountInfo = payload
        },
        IdSections(state,payload){
            state.IdSections = payload
        },
        periodData1C(state, payload){
            state.periodData1CStore = payload
        },
        succesInfo(state, payload){
            state.eventInfo = payload
        },
        alertInfo(state, payload){
            state.error = payload
        },
        addBillsB24(state, payload){
            state.BillsB24 = payload
        },
        addBillsB24forOne(state, payload){
            state.addBillsB24forOne = payload
        },
        balanceData(state, payload){
            state.balanceStore = payload
        },
        isAdmin(state, payload){
            state.admin = payload
        }
    },
    actions: {
            
            // действие берет данные из хранилища Б24 и хранилища Seeneco
            // сравнивает их, формирует общий массив и добавляет данные в хранилище,
            // параллельно обновляет данные в хранилище Б24

            // если load = 'load' - обычное наполнение таблицы,
            // если load == 'import' - добавляем в базу данные из импорта
        bitrixTrans: function({commit, dispatch}, load){
            let numberImportAccount = 0
            let sectionsActive = store.state.IdSections
            let allAccountForTable = []
            let remainder = []
            let finalResidue = []
            let num = 0 // для перебора счетов

            billingAccounts(sectionsActive)

            function billingAccounts(sections){
                if (sections[num]){
                    allAccountTable(sections[num])
                }
            }

            function allAccountTable(idAccount){
                let periodStart
                let periodEnd
                let senecoInfo
                let accountCreate
                let senecoBalance
                if (load == 'import') {
                    periodStart = dateFormat(store.state.periodData1CStore.periodStart)
                    periodEnd = dateFormat(store.state.periodData1CStore.periodEnd)
                } else {
                    if (store.state.periodData.periodStartStore){
                        periodStart = dateFormat(store.state.periodData.periodStartStore)
                        periodEnd = dateFormat(store.state.periodData.periodEndStore)
                    }
                }    

                function dateFormat(date){
                    let daySI = zeroPull(date.getDate())
                    let mounthSI = zeroPull(date.getMonth()+1)
                    let YearSI = zeroPull(date.getFullYear())
                    let newdate  = String(YearSI)+'-'+ String(mounthSI)+'-'+ String(daySI)
                    return newdate
                }

                function zeroPull(num){
                    if (String(num).length == 1){
                        num = '0' + num
                    }
                    return num
                }

                let bitrixStore = []; // будуд записаны данные из хранилища Б24
                let accountName // название счета
                let numberAccount // номер счет Сенеко
                let keySeeneco  // ключ авторизации Сенеко
                let typeAccount // тип счета
                let summAccount // начальный остаток счета

                    // получаем из хранилища б24 нужный раздел (сччет)
                axios
                    .get("https://"+obj['domain']+"/rest/entity.section.get?FILTER%5BID%5D="+idAccount+"&ENTITY=store&auth="+obj['auth'])
                    .then(response =>{
                        senecoInfo = JSON.parse(response.data.result[0].DESCRIPTION)
                        
                        accountName = senecoInfo['name']
                        accountCreate = senecoInfo['dateBalance']
                        numberAccount = senecoInfo['number']
                        keySeeneco = senecoInfo['key']
                        typeAccount = senecoInfo['type']
                        summAccount = senecoInfo['summ']

                        bitrixEntityGet()
                    })
                    .catch(error => {
                            let alert = 'Ошибка запроса счета из хранилища Б24'
                            dispatch('alertInfo', alert)
                            console.log(error)
                        }
                    );
                
                
                    // получаем из хранилища б24 все операции из раздела (счета) за указанный период
                function bitrixEntityGet(nextEl){

                    axios 
                        .post("https://"+obj['domain']+"/rest/entity.item.get",{
                            "auth": obj['auth'],
                            "entity": "store",
                            "filter": {
                                '<=DATE_ACTIVE_FROM': periodEnd,
                                '>=DATE_ACTIVE_FROM': periodStart,
                                "SECTION": idAccount,
                            },
                            "order": {
                                "sort": {"DATE_ACTIVE_FROM": "DESC"}
                            },
                            "start": nextEl
                        })
                        .then(responce => {
                            // если в ответе больше 50 позиций
                            if (responce.data.next) {
                                for (var i=0;i<responce.data.result.length;i++){
                                    bitrixStore.push(responce.data.result[i])
                                }
                                bitrixEntityGet(responce.data.next)
                            }else {
                                for (var i=0;i<responce.data.result.length;i++){
                                    bitrixStore.push(responce.data.result[i])
                                }
                                console.log(bitrixStore)
                                
                                if ((typeAccount == 'seeneco') && (load == 'load')){
                                    accountInfo()
                                }
                                else if((typeAccount == '1C') && (load == 'import')) { 
                                    if (numberAccount == store.state.import1CStore['AccountNumber']){
                                        comparison1C()
                                    } else return
                                } else if ((typeAccount == '1C') && (load == 'load')){
                                    tableArrayParse(bitrixStore).forEach(function(item){
                                        allAccountForTable.push(item)
                                        finalResidue.push(item)
                                    }) 
                                    if (load != 'import'){
                                        num = num + 1
                                        billingAccounts(sectionsActive)
                                    }
                                } else return
                            }     
                            
                        })
                        .catch(error => {
                            let alert = 'Ошибка запроса операций из хранилища Б24'
                            commit('alertInfo', alert)
                            console.log(error)
                        });
                    }
                    
                    // запрос данных из сенеко
                function accountInfo(){                  
                    let periodStartSeeneco = dateTransform(periodStart)
                    let periodEndSeeneco = dateTransform(periodEnd)                    

                    // формируются параметры для запроса
                    let finalGetProxy = "?address=https://seeneco.com/snc-core-services/api/v2/cash/accounts/" +
                    numberAccount + "/statement&periodStart=" + periodStartSeeneco + "&periodEnd=" + 
                    periodEndSeeneco + "&Authorization=" + keySeeneco;

                    axios
                        .get("/server.php" + finalGetProxy)
                        .then(response =>{
                            let Transactions = response.data.Transactions.reverse()
                            let seeneco = response.data
                            seeneco.Transactions = Transactions
                            console.log(seeneco)
                            senecoBalance = seeneco.BalanceFinish;
                            if (seeneco.errorMessage){
                                dispatch('alertInfo', 'Неправильный ключ авторизации для счета "'+accountName+'"')
                                seeneco = []
                                // return
                            }
                            if (bitrixStore.length == 0){
                                bitrixStoreFilling(seeneco)
                            } else comparison(seeneco)
                        })
                        .catch(error => {
                            let alert = 'Ошибка запроса счета из Seeneco'
                            dispatch('alertInfo', alert)
                            console.log(error)
                        });
                }    

                // обработка данных из 1С
                function comparison1C(){
                    if(bitrixStore.length == 0){
                        bitrixStoreFilling(store.state.import1CStore)
                    }  else {
                        comparisonAll(store.state.import1CStore['Transactions'])
                    }
                }

                 // перебор всех элементов хранилища б24 и добавление туда новых, либо обновление существующих
                function comparison(seeneco){
                    // if (seeneco['Transactions'].length == 0){
                    //     storeAddAll(bitrixStore)
                    // }
                    
                        comparisonAll(seeneco['Transactions']).forEach(function(item){
                            allAccountForTable.push(item)
                            finalResidue.push(item)
                        }) 
                        addSummForAccount()
                        if (load != 'import'){
                            num = num + 1
                            billingAccounts(sectionsActive)
                        } 

                }

                        // наполняет массив для хранилища если хранилище б24 пустое
                function bitrixStoreFilling(importStore){
                    addSummForAccount()
                    if (importStore['Transactions'] == undefined){
                        return console.log('Новых данных нет')
                    } else if (importStore['Transactions'].length == 0){
                        return console.log('Новых данных нет')
                    }
                    
                    importStore['Transactions'].forEach(function(seen){
                        bitrixStore.push(pushProperty(seen["TransactionUID"], seen))
                        allAccountForTable.push(seen)
                        finalResidue.push(seen)
                    })
                    if (load != 'import'){
                        num = num + 1
                        billingAccounts(sectionsActive)
                    }

                    // console.log('данные обновили, выводим таблицу и обновляем харнилище')  
                    numberImportAccount = bitrixStore.length
                    storeAddAll(bitrixStore)
                }

                // Меняет местами первый и последний элементы дат
                //  Например: если год-месяц-день, меняет на день-месяц-год
                function dateTransform(date){
                    let transform = date.substr(0,10).split('-')
                    let one = transform.slice(0,1)
                    let two = transform.slice(2)
                    transform.splice(0, 1, two)
                    transform.splice(2, 1, one)
                    return transform.join('-')
                } 
                    // создание массива для записи в хранилище Б24
                function pushProperty(name, storeItem){
                    let data = new Date(dateTransform(storeItem["TransactionDate"]))
                    if ((typeof idAccount) == 'object' ){
                        storeItem.AccountId = idAccount[0]
                    } else storeItem.AccountId = idAccount
                    storeItem.dataSort = data;
                    let storePush = 
                        {   
                            "NAME": name,
                            "DETAIL_TEXT": JSON.stringify(storeItem),
                            "PROPERTY_VALUES":{
                                "TransactionDate": storeItem["TransactionDate"]
                            }
                        }
                    return storePush
                }

                function comparisonAll(Transactions){
                    let sameNames = [] // массив совпадений
                    let differentNames = [] // массив с номерами новых оперций
                    let bitrixStoreEntity = [] // массив с новыми оперциями

                        // ищем совпадения в массивах
                    bitrixStore.forEach(function(Item){
                        for (var i=0; i<Transactions.length; i++){
                            if (!Transactions[i].Recipient || !Transactions[i].Payer || !Transactions[i].Recipient || !Transactions[i].Payer || !Transactions[i].Payer.TaxCode == '' || !Transactions[i].Recipient.TaxCode == '') {
                                if (Item['NAME'] == Transactions[i]['TransactionUID'] &&
                                    JSON.parse(Item["DETAIL_TEXT"]).TransactionDate == Transactions[i].TransactionDate &&
                                    JSON.parse(Item["DETAIL_TEXT"]).Amount == Transactions[i].Amount
                                ){
                                    sameNames.push(Transactions[i]['TransactionUID']) // сохраняем схожие номера операций сенеко и б24
                                } 
                            } else {
                                if (Item['NAME'] == Transactions[i]['TransactionUID'] &&
                                    JSON.parse(Item["DETAIL_TEXT"]).Recipient.TaxCode == Transactions[i].Recipient.TaxCode &&
                                    JSON.parse(Item["DETAIL_TEXT"]).Payer.TaxCode == Transactions[i].Payer.TaxCode && 
                                    JSON.parse(Item["DETAIL_TEXT"]).TransactionDate == Transactions[i].TransactionDate &&
                                    JSON.parse(Item["DETAIL_TEXT"]).Amount == Transactions[i].Amount
                                ){
                                    sameNames.push(Transactions[i]['TransactionUID']) // сохраняем схожие номера операций сенеко и б24
                                } 
                            }
                        }
                    })

                        // сохраняем номера оперций, которых нет в б24
                    if (sameNames.length > 0) {
                        Transactions.forEach(function(item){
                            if (!sameNames.includes(item['TransactionUID'])){
                                differentNames.push(item['TransactionUID']) // номера новых операции
                            } 
                        })
                    }

                    // если есть новые oпeрации, добавляем их в массив bitrixStoreEntity
                    if (differentNames.length > 0){
                        Transactions.forEach(function(item){
                            for (var i=0;i<differentNames.length;i++){
                                if (item['TransactionUID'] ==  differentNames[i]){
                                    bitrixStoreEntity.push(pushProperty(item["TransactionUID"], item))
                                } 
                            }
                        })
                    }

                        // если bitrixStoreEntity1C не пустой, обновляем хранилище б24
                        // и добавляем новые операции в массив bitrixStore
                    if (bitrixStoreEntity.length > 0){
                        numberImportAccount = bitrixStoreEntity.length
                        for (var i=0;i<bitrixStoreEntity.length;i++){
                            bitrixStore.push(bitrixStoreEntity[i])
                        }
                        storeAddAll(bitrixStoreEntity) 
                    } 

                    if (load == 'import'){
                          return  
                    }

                    return tableArrayParse(bitrixStore)
                  

                }

                    // парсим json с информацией для вывода в таблицу
                function tableArrayParse(arr){
                    let vuexStore = [] // общий массив со всеми операциями
                    arr.forEach(function(Items){
                        let parse = JSON.parse(Items["DETAIL_TEXT"])
                        vuexStore.push(parse)
                    })
                    return vuexStore
                }

                // обновдяет хранилище Б24
                function storeAddAll(addStore){
                    let serverTime
                    let size = 50; //размер подмассива
                    let subarray = []; //массив в который будет выведен результат.
                    for (let i = 0; i <Math.ceil(addStore.length/size); i++){
                        subarray[i] = addStore.slice((i*size), (i*size) + size);
                    }

                        // время на сервере
                    BX24.callMethod('server.time',{}, 
                    function(result)
                     {
                        serverTime = result.data().substr(10)
                        batch(subarray, 0)
                    })

                        // преобразует массив и записывает операции в базу Б24
                    function batch(arr, rang){
                        if (arr[rang]){
                            let arrCmd = {}
                            arr[rang].forEach(function(itemStore, key){
                                let dateStoreTime = dateTransform(itemStore['PROPERTY_VALUES']['TransactionDate'])
                                let name = "item_add_" + key
                                arrCmd[name] = ['entity.item.add',{
                                    "ENTITY": "store",
                                    "NAME": itemStore['NAME'],
                                    "SECTION": idAccount,
                                    "DATE_ACTIVE_FROM": dateStoreTime,
                                    "DETAIL_TEXT": itemStore['DETAIL_TEXT'],
                                    "PROPERTY_VALUES": {
                                        "TransactionDate": dateStoreTime
                                    },
                                    'auth': obj['auth']
                                }]
                            })
                            BX24.callBatch(arrCmd, function(result)
                            {  
                                batch(arr, rang+1)
                                
                                // console.log(result);  
                                
                            })
                        }
                        else {
                            if (typeAccount == "1C"){
                                dispatch('bitrixTrans', 'load')
                            }
                            addSummForAccount()
                            store.state.disable = false
                        }
                    }
                    
                }
                    // корректирует сумму счета на основании новых данных
                function addSummForAccount(){
                    let accountCreateDate = new Date(accountCreate).setHours(0, 0, 0, 0)
                    let finalResidue = []
                    // let periodStartFind = new Date(periodStart).setHours(0, 0, 0, 0)
                    let periodEndFind = new Date(periodEnd).setHours(0, 0, 0, 0)

                    if (accountCreateDate <= periodEndFind){
                        bitrixEntityResidue()
                    }
                                        
                    // получаем из хранилища б24 все операции из раздела (счета) за указанный период
                function bitrixEntityResidue(nextEl){
                        // вызываем все элементы из хранилища
                    axios
                        .post("https://"+obj['domain']+"/rest/entity.item.get",{
                            'auth': obj['auth'],
                            'ENTITY': 'store',
                            'filter':{
                                '>=DATE_ACTIVE_FROM': accountCreate,
                                '<=DATE_ACTIVE_FROM': periodEnd,
                                'SECTION': idAccount
                            },
                            'sort':{
                                'DATE_ACTIVE_FROM': 'DESC'
                            },
                            'start': nextEl
                        })
                        .then(responce => {
                            // если в ответе больше 50 позиций
                            if (responce.data.next) {
                                finalResidue = finalResidue.concat(responce.data.result)
                                bitrixEntityResidue(responce.data.next)
                            }else {
                                let sum = 0
                                finalResidue = finalResidue.concat(responce.data.result)
                                // console.log(finalResidue)
                                
                                for(var i=0; i< finalResidue.length; i++){
                                    if (JSON.parse(finalResidue[i].DETAIL_TEXT).Charge){
                                        sum = Number(sum) - JSON.parse(finalResidue[i].DETAIL_TEXT).Amount
                                    } else {
                                        sum = Number(sum) + JSON.parse(finalResidue[i].DETAIL_TEXT).Amount
                                    }
                                    
                                }
                                inputSumm(sum)
                            }  
                        })
                        .catch(error => {
                            let alert = 'Ошибка запроса операций из хранилища Б24 для остатков счета'
                            dispatch('alertInfo', alert)
                            console.log(error)
                        });
                }
                    function inputSumm(sum){
                        let Name = senecoInfo.name
                        let summa = sum + Number(summAccount)

                        let arrCheck = []
                        for (let i=0;i<remainder.length;i++){
                            arrCheck.push(remainder[i].id)
                        }
                        if (arrCheck.indexOf(idAccount) == -1){
                            remainder.push({
                                'Name': Name,
                                'Summ': summa.toFixed(2),
                                'id': idAccount,
                                'balance': senecoBalance
                            })
                        } else {
                            remainder[arrCheck.indexOf(idAccount)].Summ = Number(summa).toFixed(2)
                            remainder[arrCheck.indexOf(idAccount)].balance = senecoBalance
                        }
                        commit('balanceData',remainder)   
                    }
                }
          }  

        if (load == 'import'){
            setTimeout(function(){
                if (numberImportAccount == 0){
                    store.state.disable = false
                }
                let infoSucces = 'В файле всего '+store.state.import1CStore['Transactions'].length+' операций, загружено '+numberImportAccount
                dispatch('succesInfo', infoSucces)
                dispatch('bitrixTrans', 'load')
            }, 1000)
        } else {
            
                // allAccountTable 
            console.log(allAccountForTable);
            commit('loadAccount', allAccountForTable)
        }
        }, // конец bitrixTrans
        
            // действие наполняет таблицу счетов
        accounsInformation: function({commit, dispatch}){
            let section = []
            let activeSections = [] // для ID разделов активных счетов
            axios
                .get("https://"+obj['domain']+"/rest/entity.section.get?ENTITY=store&auth="+obj['auth'])
                .then(responce => {
                    let results = responce.data.result
                    if (results.length == 1){
                        if (results[0].ACTIVE == "Y"){
                            activeSections.push(results[0].ID)
                        }
                        let parser = JSON.parse(results[0].DESCRIPTION)
                        section.push({
                            "Name": results[0].NAME,
                            "Type": parser['type'],
                            "Activity": results[0].ACTIVE,
                            "Number": parser['number'],
                            "ID": results[0].ID,
                            "Key": parser['key'],
                            'Summ': parser['summ'],
                            "dateBalance": parser['dateBalance']
                        })
                    } else {
                        for (var k=0; k<results.length;k++){
                            if (results[k].ACTIVE == "Y"){
                                activeSections.push(results[k].ID)
                            }
                            let parser = JSON.parse(results[k].DESCRIPTION)
                            section.push({
                                "Name": results[k].NAME,
                                "Type": parser['type'],
                                "Activity": results[k].ACTIVE,
                                "Number": parser['number'],
                                "ID": results[k].ID,
                                "Key": parser['key'],
                                'Summ': parser['summ'],
                                "dateBalance": parser['dateBalance']
                            })
                        }
                    }
                    commit('accounsInformationMut', section)
                    commit('IdSections',activeSections)
                    dispatch('bitrixTrans', 'load')
                })
                .catch(error => {
                    // let alert = 'В программе не заведено '
                    // dispatch('alertInfo', alert)
                    // console.log(error)
                });
        },
            // считывате счета Б24
        addBillsB24: function({commit} ,response){
            commit('addBillsB24', response) 
            },
                // вызывает мутацию для отображения ошибок
            alertInfo: function({commit}, alert){
                commit('alertInfo', alert)
            },
                // вызывает мутацию для отображения информации
            succesInfo: function({commit}, alert){
                commit('succesInfo', alert)
            },
        }
})

export default store