<template>
<div>
    <b-container fluid>  
        <!-- Шапка таблиц -->
        <b-row class="mb-2">
            <b-col md="9">
                <h3>Перечень счетов</h3>
            </b-col>
            <b-col md="3">
                <div>
                <b-btn variant="success" @click="showModal">+ Добавить счет</b-btn>
                <!-- Modal Component -->
                <b-modal hide-footer hide-centered title="Данные о счете" ref="myModalRef">
                    <div>
                        <b-form>
                        <b-input class="mb-2" placeholder="Имя счета" v-model="form.name" autocomplete="off"/>
                        <b-input class="mb-2" placeholder="Номер счета" v-model="form.number" autocomplete="off"/>
                        <b-form-select  class="mb-2"
                                        value="seeneco"
                                        :options="typeAccount"
                                        v-model="form.type"
                                        >
                        </b-form-select>
                        <b-form-textarea :rows="4" :max-rows="6" v-if="form.type=='seeneco'" class="mb-2" placeholder="Ключ" v-model="form.key" autocomplete="off"></b-form-textarea>
                        <!-- <b-input v-if="form.type=='seeneco'" class="mb-2" placeholder="Ключ" v-model="form.key" autocomplete="off"/> -->
                        <h6>Введите дату и сумму начального остатка</h6>
                        <b-input class="mb-2" placeholder="Сумма" v-model="form.summ" value="0" autocomplete="off"/>
                        <b-input class="mb-2" type="date" v-model="form.dateBalance" min="2000-01-01" max="2050-01-01"/>
                        <p v-if="alertForm" class="text-danger font-weight-bold">{{alertText}}</p>
                        <b-button  variant="success" @click="hideModal"  >Сохранить</b-button>
                        </b-form>
                    </div>
                </b-modal>
            </div>
            </b-col>
        </b-row>
        <!-- Конец шапки таблиц -->
        <!-- Таблица -->
        <b-table striped hover
                :items="count"
                :fields="fields"
                >
                <!-- колонка Имя счета -->
        <template slot="Name" slot-scope="row"> 
            <span v-b-tooltip.hover >{{row.value}} <img class="pointer" title="Редактировать" src="/js/ae3f44e104c3bb5041c88a613ee6e70f.png" @click="accounChangeShow(row.item)"></span>
        </template> 
                <!-- колонка Имя счета -->
                <!-- колонка активность -->
        <template slot="Activity" slot-scope="row">   
            <b-form-checkbox @change="activeAccount(row.item)" :checked="row.item.Activity == 'Y' ? true: false">
            </b-form-checkbox>
        </template> 
                <!-- колонка активность -->
         <!-- колонка дейстия -->
        <template slot="Action" slot-scope="row">   
            <div>
            <b-btn v-if="row.item.Type=='1C'"  size="sm" @click="showLoadModal(row.item)" variant="success"  :disabled="diss">Импорт</b-btn>
            </div>  
        </template>
        <!-- колонка дейстия -->
        <!-- колонка остаток -->
        <template slot="Sediment" slot-scope="data">   
            {{data.item.Summ}} р. ({{dateTransForSortDash(data.item.dateBalance)}})
        </template>
        <!-- колонка остаток -->
        <!-- колонка удалить -->
        <template slot="Delete" slot-scope="row">
            <b-button variant="danger" @click.stop="deleteAccount(row.item)" size="sm" >x</b-button>
        </template>
        <!-- колонка удалить -->
        </b-table>
        <!-- Таблица -->
        <b-modal hide-footer hide-centered title="Редактирование счета" ref="changeAccountRef">
                <b-container fluid>
                    <b-row class="my-1">
                        <b-col sm="3"><label for="num">Номер</label></b-col>
                        <b-col sm="9"><b-form-input id="num" :value="modifiedData['Number']" v-model="newNumber"></b-form-input></b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col sm="3"><label for="name">Имя</label></b-col>
                        <b-col sm="9"><b-form-input id="name" :value="modifiedData['Name']" v-model="newName"></b-form-input></b-col>
                    </b-row>
                    <b-row class="my-1" v-if="accountChangeKeyShow">
                        <b-col sm="3"><label for="key">Ключ</label></b-col>
                        <!-- <b-col sm="9"><b-form-input id="key" :value="modifiedData['key']" v-model="newKey"></b-form-input></b-col> -->
                        <b-col sm="9"><b-form-textarea :rows="4" :max-rows="6" id="key" :name="modifiedData['key']" v-model="newKey"></b-form-textarea></b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col sm="3"><label for="summ">Остаток</label></b-col>
                        <b-col sm="9"><b-form-input id="summ" :value="modifiedData['summ']" v-model="newSumm"></b-form-input></b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col sm="3"><label for="dateBalance">Дата остатка</label></b-col>
                        <b-col sm="9"><b-form-input type="date" id="dateBalance" :value="modifiedData['dateBalance']" v-model="newDateBalance"></b-form-input></b-col>
                    </b-row>
                    
                </b-container>
                <b-button variant="success" @click="accounChange()">Сохранить</b-button>
        </b-modal>
                <!-- Modal Component -->
                <b-modal hide-footer hide-centered title="Загрузка файла выписки 1С" ref="myLoadModalRef">
                    <div>
                        <b-form>
                        <b-form-file class="mb-3" plain accept=".txt" v-model="file" placeholder="Выберите файл" ref="fileinput"></b-form-file>
                        <b-button variant="success" @click="consolefile()">Загрузить</b-button>
                        </b-form>
                    </div>
                </b-modal>
                <!-- Modal Component -->
                <b-modal hide-footer hide-centered title="Вы уверены, что хотите удалить счет и всю информацию о нем?" ref="myDeletModalRef">
                    <div>
                        <b-form>
                            <b-button variant="danger" @click="deleteYes">Удалить!</b-button>
                            <b-button variant="success" @click="deleteNo">Отмена</b-button>
                        </b-form>
                    </div>
                </b-modal>
        
    </b-container>
</div>
</template>

<script>

import axios from 'axios'
import img from '../../img/icon.png'

    export default {
        data(){
            return{
                fields:{
                    Name:{
                        key: 'Name',
                        label: 'Имя счета',
                        sortable: true
                    },
                    Type:{
                        key: 'Type',
                        label: 'Тип счета',
                        sortable: true
                    },
                    Action:{
                        label: 'Действие'
                    },
                    Active:{
                        key: 'Activity',
                        label: 'Активность'
                    },
                    Sediment:{
                        // key: 'Summ',
                        label: "Остаток (дата)"
                    },
                    Delete: {
                        label: "Удалить"
                    }
                },
                form: { // сохраняет данные при заполнении формы нового счета
                    name: '',
                    number:'',
                    type: 'seeneco',
                    key: '',
                    summ: '',
                    dateBalance: '2019-01-01'
                },
                file: null, // загружаемый файл
                account1C: '', // загружаемый файл
                typeAccount: ['seeneco', '1C'], // выбор типа нового счета
                idDeleteAccount: '', // данные, какой счет удалить
                parseFile: '',
                newNumber: '', // новый номер счета
                newName: '', // новое имя
                newKey: '', // новвый ключ
                newSumm: '', // новвый остаток по счету
                newDateBalance: '', // новая дата начального остатка
                accountChangeKeyShow: false, // показывать смену ключа
                modifiedData: '',
                importInfo: '', // данные о счете, для импорта
                alertForm: false,
                alertText: ''
            }
        },
        mounted() {
            this.$store.dispatch("accounsInformation")
        },
        computed: {
            count: function(){
                if ((typeof this.$store.getters.getAccountInfo) == "object"){
                    return this.$store.getters.getAccountInfo
                }
            },
            diss: function(){
                return this.$store.state.disable
            }
        },
        methods: {
                // импорт из 1С
            consolefile: function(){
                this.$store.state.disable = true
                let that = this
                let data = this.importInfo
                let reader = new FileReader()
                reader.readAsText(this.file, 'Windows-1251')
                reader.onload = function(){
                    let parseData = reader.result
                    account1cDev(parseData)
                }

                function account1cDev(parseData){
                    let arrayMainClear = [] // окончательный массив документов
                    let arrayMain1c
                    let parseOp = parseData.split('СекцияДокумент') 
                    
                    for(var i=0;i<parseOp.length;i++){
                        let stringDocs = parseOp[i].split('\n') 
                        let arrayWord = [] // массив со свойствами докумена
                        
                        stringDocs.forEach(function(item){  
                                let word = item.split('=')
                                if (word.length == 1){
                                    arrayWord[word[0]] = '-'
                                } else {
                                    arrayWord[word[0]] = word[1]
                                }               
                        })
                        arrayMainClear.push(arrayWord)
                    }
                        
                   
                        // праверка на правильность добавления счета
                        if (!arrayMainClear[0]['РасчСчет']){
                            let alert = 'Неверный формат выписки'
                            that.$store.dispatch('alertInfo', alert)
                            that.$store.state.disable = false
                            return
                        }
                        arrayMainClear[0]['РасчСчет'] = arrayMainClear[0]['РасчСчет'].replace(/\s+/g, '')
                        data.Number = data.Number.replace(/\s+/g, '')

                    if(arrayMainClear[0]['РасчСчет'] != data.Number){
                        let alert = 'Номера счетов не совпадают'
                         that.$store.state.disable = false
                        return that.$store.dispatch('alertInfo', alert)
                    }
                        // создает массив с данными о счете
                    arrayMain1c = {
                        'AccountNumber': arrayMainClear[0]['РасчСчет'],
                        'Transactions': []
                    }
                   let arrayWithouthAccount = arrayMainClear.splice(1)


                    // создает массив с данными об операциях
                   arrayWithouthAccount.forEach(function(transactions){
                       let dateFromStore = that.$store.getters.getPeriodData
                             // преобразует дату к виду для сортировки
                       let dateForSort = new Date(that.dateTransForSort((transactions['Дата'])+"T12:00:00.000Z"))
                       let formatData = `${dateForSort.getFullYear()}-${dateForSort.getMonth()}-${dateForSort.getDate()}`
                        let dateForTable = dateTransformForTable(transactions['Дата'])
                        let Charge
                        if(transactions['ДатаПоступило'].length == 1){
                            Charge = true
                        } else {
                            Charge = false
                        }

                        arrayMain1c['Transactions'].push({
                            "TransactionUID": transactions['Номер'].replace(/\s+/g, ''),
                            'AccountId': data.ID.replace(/\s+/g, ''),
                            'Amount': transactions['Сумма'].replace(/\s+/g, ''),
                            'Recipient':{
                                'Name': transactions['Получатель'],
                                'TaxCode': transactions['ПолучательИНН'].replace(/\s+/g, '')
                            },
                            'Payer':{
                                'Name': transactions['Плательщик'],
                                'TaxCode': transactions['ПлательщикИНН'].replace(/\s+/g, '')
                            },
                            'PayerAccount': {
                                'BankName': transactions['ПлательщикБанк1'],
                                'BankBIC': transactions['ПлательщикБИК'].replace(/\s+/g, ''),
                                'AccountNumber': transactions['ПлательщикРасчСчет'].replace(/\s+/g, '')
                            },
                            'RecipientAccount': {
                                'BankName': transactions['ПолучательБанк1'].replace(/\s+/g, ''),
                                'BankBIC': transactions['ПолучательБИК'].replace(/\s+/g, ''),
                                'AccountNumber': transactions['ПолучательРасчСчет'].replace(/\s+/g, '')
                            },
                            'Reference': transactions['НазначениеПлатежа'],
                            'dataSort': formatData.replace(/\s+/g, ''),
                            'Charge': Charge,
                            'TransactionDate': dateForTable.replace(/\s+/g, '')

                        })
                   })

                   if (arrayMain1c["Transactions"].length == 0){
                       let alert = 'за данные период операций не найдено'
                       that.$store.dispatch('alertInfo', alert)
                   } else{
                       let success = "Данные успешно загружены"
                       that.$store.dispatch('succesInfo', success)
                       that.$store.dispatch("import1C", arrayMain1c)
                   } 
                }
                this.$refs.fileinput.reset()

                this.$refs.myLoadModalRef.hide()

                function dateTransformForTable(date){
                    let transform = date.substr(0,10).split('.')
                    return transform.join('-')
                }                 
            },
                // приводит дату к нужному виду
            dateTransForSort: function(date){
                let transform = date.substr(0,10).split('.')
                let one = transform.slice(0,1)
                let two = transform.slice(2)
                transform.splice(0, 1, two)
                transform.splice(2, 1, one)
                return transform.join('.')
            },
                // приводит дату к нужному виду
            dateTransForSortDash: function(date){
                let yearTransform = date.substr(2)
                let transform = yearTransform.substr(0,8).split('-')
                // let transform = date.substr(0,10).split('-')
                let one = transform.slice(0,1)
                let two = transform.slice(2)
                transform.splice(0, 1, two)
                transform.splice(2, 1, one)
                return transform.join('.')
            },

             // открыть модальное окно формы нового счета
            showModal: function() {
                this.$refs.myModalRef.show()
            },
                // закрыть модальное окно формы нового счета и добавить новый счет
            hideModal: function() {
                    // проверка, пустая ли форма
                if (this.form.name == '' || this.form.number == '' || (this.form.key == '' && this.form.type == 'seeneco')){
                    this.alertForm = true
                    this.alertText = 'Форма заполнена не полностью'
                    return
                }
                this.alertForm = false
                    // проверка на уникальность имени счета
                let account = this.$store.getters.getAccountInfo
                for (var i=0; i<account.length;i++){
                    if (account[i].Name == this.form.name){
                        this.alertText = "Счет с таким именем уже существует"
                        this.alertForm = true
                        return
                    }
                }
                    // проерка на уникальность номера счета
                for (var i=0; i<account.length;i++){
                    if (account[i].Number == this.form.number){
                        this.alertText = "Счет с таким именем уже существует"
                        this.alertForm = true
                        return
                    }
                }
                    // остаявлет только цыфры
                let number =  this.form.number.replace(/\D+/ig, '')

                    // остаявлет только цыфры и точку
                let data = this.price(this.form.summ)

                let description = JSON.stringify({
                    "name": this.form.name,
                    "key": this.form.key,
                    "number": number,
                    "type": this.form.type,
                    "summ": data,
                    "dateBalance": this.form.dateBalance
                });   

                this.$store.dispatch("accountSectionAdd", description)

                this.$refs.myModalRef.hide()

                this.form.name = ''
                this.form.key = ''
                this.form.number = ''
                this.form.summ = '',
                this.form.dateBalance = '2018-01-01'
            },
                // открыть модальное окно загрузки счета из 1С
            showLoadModal: function(value){
                this.importInfo = value
                this.$refs.myLoadModalRef.show()
            },
                // закрыть модальное окно загрузки счета из 1С
            hideLoadModal: function(){
                this.$refs.myLoadModalRef.hide()
            },
                // предупреждение удаления счета
            deleteAccount: function(item){
                this.idDeleteAccount = item.ID
                this.$refs.myDeletModalRef.show()
            },
                // закрыть предупреждение удаления счета
            deleteNo:function(){
                this.$refs.myDeletModalRef.hide()
            },
                // закрыть предупреждение удаления счета и удалить счет
            deleteYes:function(){
                this.$refs.myDeletModalRef.hide()
                let id = this.idDeleteAccount
                this.$store.dispatch("deleteSection", id)
            },  
                // изменать активность (отображения счета в таблице)
            activeAccount: function(item){
                let Activ = item.Activity
                let ID = item.ID
                let name = item.Name
                this.$store.dispatch("changeSection",{Activ, ID, name})
            },
                // модальное окно смены реквизитов
            accounChangeShow: function(item){
                if (!item.Summ){
                    item.Summ = '0'
                }

                this.newName = item.Name
                this.newNumber = item.Number.replace(/\D+/ig, '')
                this.newSumm = item.Summ
                this.newDateBalance = item.dateBalance

                if(item.Type == 'seeneco'){
                    this.accountChangeKeyShow = true
                    this.modifiedData = {
                        'ID': item.ID,
                        'Name': item.Name,
                        'Number': item.Number,
                        'key': item.Key,
                        'type': item.Type,
                        'summ': item.Summ,
                        "dateBalance": item.dateBalance
                    }
                    this.newKey = item.Key
                } else {
                    this.accountChangeKeyShow = false
                    this.modifiedData = {
                        'ID': item.ID,
                        'Name': item.Name,
                        'Number': item.Number,
                        'type': item.Type,
                        'summ': item.Summ,
                        "dateBalance": item.dateBalance
                    }
                }
                this.$refs.changeAccountRef.show()
            },
                // закрывает модальное окно смены реквизитов и меняет реквизиты
            accounChange: function(){
                if (this.newName == '' || this.newNumber == '' || this.newSumm == '' || this.newDateBalance == ''){
                    let alert = "Форма заполнена не полностью"
                    this.$store.dispatch("alertInfo", alert)
                    this.$refs.changeAccountRef.hide()
                    return
                }

                let Name, NumberCh, key, ID, type, summ, dateBalance
                let that = this

                if(this.modifiedData.key == undefined){
                    Name = empty(this.newName, 'Name')
                    NumberCh = empty(this.newNumber, 'Number')
                    ID = this.modifiedData.ID
                    type = this.modifiedData.type
                    summ = this.price(empty(this.newSumm, 'Summ')),
                    dateBalance = empty(this.newDateBalance, 'dateBalance')

                    this.$store.dispatch("changeName",{Name,ID,NumberCh,type,summ,dateBalance})
                } else {
                    Name = empty(this.newName, 'Name')
                    NumberCh = empty(this.newNumber, 'Number')
                    ID = this.modifiedData.ID
                    key = empty(this.newKey, 'key')
                    type = this.modifiedData.type
                    summ = this.price(empty(this.newSumm, 'Summ')),
                    dateBalance = empty(this.newDateBalance, 'dateBalance')

                    this.$store.dispatch("changeName",{Name,ID,NumberCh,type,key,summ,dateBalance})
                }

                function empty(value, empty){
                    if(value == ''){
                        return that.modifiedData[empty]
                    } else {
                        return value
                    }
                }
                    this.newNumber = ''
                    this.newName = ''
                    this.newKey = ''
                    this.newSumm = ''
                    this.newDateBalance = ''

                    this.$refs.changeAccountRef.hide()

                    
            },
                // форматирует начальный остаток
            price: function(summ){
                summ = String(summ)
                if (summ.indexOf(",") != -1){
                    summ = summ.replace(/,/g, ".")
                } 
                if (summ.indexOf(".") != '-1') {
                    summ=summ.substring(0, summ.indexOf(".") + 3);
                }
                summ = Number(summ.replace(/^\.|[^\d\.]/g, ''))

                return summ
            }
        }
    }
</script>

<style>
    .pointer{
        cursor: pointer
    }
</style>