import axios from 'axios'

export default {
    actions:{
         // дабавляет новый раздел (счет)
         accountSectionAdd: function({dispatch}, description){
            let info = JSON.parse(description)
            axios
                .get("https://"+obj['domain']+"/rest/entity.get?auth="+obj['auth'])
                .then(responce => {
                    let store = responce.data.result
                    let storeflag = false
                    for (var i=0;i<store.length; i++){
                        if (store[i]['ENTITY'] == "store"){
                            storeflag = true
                            break
                        }
                    }
                    if (storeflag) {
                        sectionAdd()
                    } else createStoreStore()
                })
                .catch(error => {
                    let alert = 'Ошибка проверки хранилища'
                    dispatch('alertInfo', alert, {root: true})
                    console.log(error)
                }); 

                // создает хранилище, если его не было
            function createStoreStore(){
                axios
                    .post("https://"+obj['domain']+"/rest/entity.add",{
                        "auth": obj['auth'],
                        "entity": "store",
                        "NAME":"store",
                        "access":{
                            AU: "W"
                        }
                    }) 
                    .then(responce => {
                        sectionAdd()
                    })
                    .catch(error => {
                        let alert = 'Ошибка создания хранилища'
                        dispatch('alertInfo', alert, {root: true})
                        console.log(error)
                    }); 
            }
           

                function sectionAdd(){
                    axios
                        .get("https://"+obj['domain']+"/rest/entity.section.add?auth="+
                        obj['auth']+"&ENTITY=store&NAME="+info.name+"&DESCRIPTION="+description)
                        .then(responce => {
                            let infoSucces = 'Счет успешно добавлен'
                            dispatch('succesInfo', infoSucces, {root: true})
                            dispatch('accounsInformation',null, {root: true})
                        })
                        .catch(error => {
                            let alert = 'Ошибка создания счета'
                            dispatch('alertInfo', alert, {root: true})
                            console.log(error)
                        });
                }
        },
        // изменяет активность счета (раздела)
        changeSection: function({dispatch},{Activ, ID, name}){
            let infoSucces
            let change
            if (Activ == "Y"){
                change = "N"
                infoSucces = 'Счет "' +name+'" не будет отображаться в таблице'
            } else if (Activ == "N"){
                change = "Y"
                infoSucces = 'Счет "'+name+'" будет отображаться в таблице'
            }
            axios
                .get("https://"+obj['domain']+"/rest/entity.section.update?auth="+
                obj['auth']+"&ENTITY=store&ID="+ID+"&ACTIVE="+change)
                .then(responce => {
                    dispatch('succesInfo', infoSucces, {root: true})
                    dispatch('accounsInformation', null, {root: true})
                })
                .catch(error => {
                    let alert = 'Ошибка активности'
                    dispatch('alertInfo', alert, {root: true})
                    console.log(error)
                });
        },
        // меняет реквизиты счета (раздела)
        changeName: function({dispatch}, {Name,ID,NumberCh,type,key,summ,dateBalance}){
            let description
            if (key == undefined){
                description = JSON.stringify({
                    "name": Name,
                    "number": NumberCh,
                    "type": type,
                    "summ": summ,
                    "dateBalance": dateBalance
                });
            } else {
                description = JSON.stringify({
                    "name": Name,
                    "key": key,
                    "number": NumberCh,
                    "type": type,
                    "summ": summ,
                    "dateBalance": dateBalance
                });
            }
            axios
                .get("https://"+obj['domain']+"/rest/entity.section.update?auth="+
                obj['auth']+"&ENTITY=store&ID="+ID+"&NAME="+Name+"&DESCRIPTION="+description)
                .then(responce => {
                    let infoSucces = 'Счет поменял данные'
                    dispatch('succesInfo', infoSucces, {root: true})
                    dispatch('accounsInformation', null, {root: true})
                })
                .catch(error => {
                    let alert = 'Ошибка заммены данных счета'
                    dispatch('alertInfo', alert, {root: true})
                    console.log(error)
                });
        }, 
        // удаляет счет (раздел)
        deleteSection: function({commit,dispatch}, id){
            let process = 'Счет удаляется'
            dispatch('alertInfo', process, {root: true})
            axios
                .get("https://"+obj['domain']+"/rest/entity.section.delete?auth="+
                obj['auth']+"&ENTITY=store&ID="+id)
                .then(responce => {
                    let infoSucces = 'Счет успешно удален'
                    dispatch('succesInfo', infoSucces, {root: true})
                    dispatch('accounsInformation',null , {root: true})
                })
                .catch(error => {
                    let alert = 'Ошибка удаления счета'
                    dispatch('alertInfo', alert, {root: true})
                    console.log(error)
                });
        },
    }
}