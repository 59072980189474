<template>
    <div>
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group
                id="exampleInputGroup1"
                label="Email адрес:"
                label-for="exampleInput1"
            >
                <b-form-input
                id="exampleInput1"
                type="email"
                v-model="form.email"
                required
                placeholder="Введите Вашу почту" />
            </b-form-group>
            <b-row class="my-1">
                <b-col>
                    <label for="input-text">Text:</label>
                </b-col>
            </b-row>
            <b-row class="my-2">
                <b-col>
                    <b-form-textarea rows="3" max-rows="6" id="input-text" type="text" placeholder="Введите Ваше сообщение" v-model="form.text" />
                </b-col>
            </b-row>
            <b-button type="submit" variant="primary">Отправить</b-button>
        </b-form>
    </div>
</template>


<script>
export default {
    data(){
        return{
            form: {
                email: '',
                text: '',
                alert: false,
                testAlert: ''
            }
        }
    }, 
    methods: {
        showModal() {
            this.$refs.myModalSup.show()
        },
        onSubmit(evt) {
            let that = this
            // let re = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i
            evt.preventDefault()
            fetch(`https://wh.exdata.net/handler.php?email=${this.form.email}&text=${this.form.text}&from=fin24&prop=icon`)  
                .then(  
                    function(response) {  
                        if (response.status !== 200) {  
                            console.log('Looks like there was a problem. Status Code: ' +  response.status);  
                            return;  
                        }
                        response.text().then(function(data) {  
                            let d = JSON.parse(data)
                            if (d.mail){
                                let alert = 'сообщение успешно отправленно'
                                that.$store.dispatch('succesInfo', alert)
                            }else {
                                let alert = 'Ошибка сервера, попробуйте позднеее'
                                that.$store.dispatch('alertInfo', alert)
                            }
                            console.log('сообщение успешно отправленно')
                        });  
                    }  
                )  
                .catch(function(err) { 
                    let alert = 'Ошибка отправки сообщения' 
                    that.$store.dispatch('alertInfo', alert)
                    console.log('Fetch Error :-S', err);  
                });
        },
        onReset(evt) {
            evt.preventDefault()
            this.form.email = ''
            this.form.text = ''
            this.$refs.myModalSup.hide()
        }
    }
}
</script>