<template>

<b-container fluid>
        <b-row>
        <b-alert v-for="summ in accountInfoForSumm" :key="summ.Name" variant="success" show class="d-inline-block mr-2">
          <span v-if="summ.Summ == summ.balance">{{summ.Name}}: {{summ.Summ}}р.</span>
          <span v-else>Остаток по счету: {{summ.Name}}: {{summ.Summ}}р., Банковский остаток: {{ summ.balance }}</span>
        </b-alert>
      </b-row>
      <b-row align-h="center" class="mb-1">
            <b-col cols="2">
            <div class="mb-1">
              <datepicker
                    :value="date1" 
                    v-model="date1"
                    :format="format"
                    :language="ru"
                    :highlighted="highlighted"
                    :monday-first = true
                    @selected="balancee($event, 'start')"
                    input-class="datepickerfilt"
                >
              </datepicker>
              </div>
              </b-col>
              <b-col cols="2">
              <div class="mb-1">
                 <datepicker
                     :value="date2" 
                     v-model="date2"
                     :format="format"
                     :language="ru"
                     :highlighted="highlighted"
                     :monday-first = true
                     @selected="balancee($event, 'end')"
                     input-class="datepickerfilt"
                 >
               </datepicker>
            </div>
            </b-col>
            <b-col cols="2">
                <b-form-select :options="accountOptions" v-model="filter.filterAccount" class="mb-1"/>
            </b-col>
              <b-col cols="3" v-if="getAdmin">
                <b-form-select :options="debetOptions" v-model="filter.filterDebet" class="mb-1"/>
            </b-col>
            <b-col cols="3">
                <b-form-select :options="heldOptions" v-model="filter.filterheld" class="mb-1"/>
            </b-col>
      </b-row>

    
    <b-table striped hover
            :items="mainAcciunt"
            :fields="fields"
            :filter-function="filterOpt"
            :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :fixed="fixed"
            @filtered="onFiltered"
            >
            <!-- настройка размера колонок -->
      <template slot="table-colgroup">
        <col span='1' width='120'>
        <col span='1' width='180'>
        <col span='1' width='90'>
        <col span='1' width='380'>
        <col span='1' width='120'>
        <col span='1' width='90'>
      </template>
        <!-- настройка размера колонок -->
      <!-- колонка действие -->
      <template slot="Action" slot-scope="row">
        <div>
          <b-btn v-if="!row.item.Charge && row.item.hrefBill==undefined" @click="showBillsModal(row.item, getBills, 'find')" size="sm" variant="danger">Найти</b-btn>
          <div v-else-if="!row.item.Charge && row.item.hrefBill">
            <a :href=row.item.hrefBill>{{ row.item.nameBill }}</a><br>
            <span class="checkBill" @click="showBillsModal(row.item, getBills, 'find')">Другой счет</span>
          </div>
        </div>
      </template>
      <!-- колонка действие -->
      <!-- колонка операции -->
      <template slot="Reference" slot-scope="row">
        <span @click="row.toggleDetails">{{ row.item.Reference.slice(0,35) }}...</span>
      </template>
      <template slot="row-details" slot-scope="row">
        <span>{{ row.item.Reference }}</span>
      </template>
      <!-- колонка операции -->
    </b-table>

    <!-- модальное окно для связки счета -->
    <b-modal size="lg" hide-footer hide-centered title="Найденные счета" id="modal" ref="billsRef" @hidden="hideBillsModal">
      <div>
          <b-row>
            <h3 class="mx-auto danger" v-if="!billtable">Счета не найдены</h3>
          </b-row>
          <b-row v-if="billtable">
            <b-col class="col-2">Дата</b-col>
            <b-col class="col-2">Сумма</b-col>
            <b-col class="col-3">Контрагент</b-col>
            <b-col class="col-3">Название счета</b-col>
            <b-col class="col-2"></b-col>
          </b-row>
          <b-row  v-for="bill in arrBill" :key="bill.ID_BILL" class="mb-1">
            <b-col class="col-2">{{ dateTransForSort(bill.DATE_INSERT) }}</b-col>
            <b-col class="col-2">{{bill.PRICE}} р. {{ bill.DATE_PAYED == "Y" ? "(Оплачен)" : ""}}</b-col>
            <b-col class="col-3">{{bill.INVOICE_PROPERTIES.COMPANY}}</b-col>
            <b-col class="col-3"><a :href=bill.href>{{bill.ORDER_TOPIC}}</a></b-col>
            <b-col class="col-2"><b-btn variant="success" size="sm" :data="bill.ID" :data-date="bill.payDate" @click="tieAccount($event)">Связать</b-btn></b-col>
          </b-row>
          <b-row>
            <b-btn variant="primary" class="w-100 m-3" @click="billsFindModal()">Найти вручную</b-btn>
          </b-row>
        </div>
    </b-modal>
      <!-- модальное окно для связки счета -->
      <!-- модальное окно поиска счета вручную -->
          <b-modal size="lg" ref="billsFindRef" hide-footer hide-centered title="Поиск счетов" @hidden="hideBillsFindModal">
            <b-row class="mb-2">
              <b-col class="col-3"><p class="font-weight-bold">Дата от </p></b-col>
              <b-col class="col-3">
                <b-form-input type="date" v-model="date3" class="datepicker"/>
              </b-col>
              <b-col class="col-3"><p class="font-weight-bold"> дата до</p></b-col>
              <b-col class="col-3">
                <b-form-input type="date" v-model="date4" class="datepicker"/>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col  class="col-3"><p class="font-weight-bold" >Сумма от </p></b-col>
              <b-col class="col-3">
                <b-form-input type="number" v-model="billsFrom" :value="billsFrom"></b-form-input>
              </b-col>
              <b-col class="col-3"><p class="font-weight-bold">сумма до </p></b-col>
              <b-col class="col-3">
                <b-form-input type="number" v-model="billsEnd" :value="billsEnd"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-form-checkbox class="mx-auto" v-model="billsCheck">
                <p class="font-weight-bold">Искать в оплаченных</p>
              </b-form-checkbox>
            </b-row>
            <b-row>
              <p v-show="alertFlag" class="serachAlert">{{ Alert }}</p>
            </b-row>
            <b-row>
              <b-btn variant="primary" class="w-100 m-2" @click="billsFindforOne()">Найти</b-btn>
            </b-row>
          </b-modal>
          <!-- модальное окно поиска счета вручную -->
    <b-row>
      <b-col md="4" class="my-1">
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
      </b-col>
      <b-col md="2"></b-col>
    <b-col md="6" class="my-1">
        <b-form-group horizontal label="Кол-во операций на странице" class="mb-0">
          <b-form-select :options="numberOfСolumns" v-model="perPage">
            <template slot="first">
              <option :value="Number(getPages)">{{getPages}}</option>
          </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
</b-container>

</template>

<script>

import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import {ru} from 'vuejs-datepicker/dist/locale'

export default {
  data () {
    return {
        sortBy: 'dataSort',
        sortDesc: true,
        fields: { // названия в шапке таблицы
        Date: {
          key: 'dataSort',
          label: 'Дата сделки',
          sortable: true,
          sortDesc: false,
          formatter: 'userDate'
        },
        Payer: {
          key: 'Payer.Name', // элемент в массиве items
          label: 'Имя плательщика'// имя столбца
        },
        Amount: {
          key: 'Amount', // элемент в массиве items
          label: 'Сумма', // имя столбца
          sortable: true, // сортировка
          tdClass: "textColor", // у всех td столбца цвет текста будет красный
          formatter: 'plusMinus'

        },
        Reference: {
          key: 'Reference',
          label: 'Операция',
          sortable: true
        },
        Account: {
          key: 'AccountId',
          label: 'Имя счета',
          sortable: true,
          formatter: 'accountNameFromId'
        },
        Action:{
            label: 'Связь со счетом'
        }       
      },
        filter: {
          filterAccount: 'Все счета',
          filterDebet: 'Приход/Расход',
          filterheld: 'Со счетом/Без счета',
        }, // значение строки фильтра
        currentPage: 1, // какую таблицу отображать, начиная с первой
        perPage: null, // сколько строк отображается на странице
        totalRows: 1, // сколько страниц будет в таблице
        fixed: false,
        date1: '',
        date2: '',
        date3: '',
        date4: '',
        highlighted: {
            days: [6, 0],
            dates: [ 
              new Date()
            ]
        },
        format: "dd.MM.yyyy",
        ru: ru,
        pageOptions: [],
        numberOfСolumns: [],
        accountOptions: ['Все счета'],
        debetOptions: ['Приход/Расход','Приход','Расход'],
        heldOptions: ['Со счетом/Без счета', 'Проведенные', 'Не проведенные'],
        // filterDebet: 'Приход/Расход',
        filterAccount: 'Все счета',
        filterheld: 'Со счетом/Без счета',
        arrBill: [],
        billtable: false, // если нет совпадений счетов
        billsFrom: 0, // диапозон суммы поиска счетов ОТ
        billsEnd: 0, // диапозон суммы поиска счетов ДО
        billsCheck: false,  // искать в оплаченных счетах
        valueBills: [],
        accountInfoForSumm: [], // информация о всех счетах в программе
        valueNow:'',
        alertFlag: false,
        Alert: '',
    }
  },
    computed: {
        getPages: {
          get(){
            let changeNumber = [10,25,50]
            if (this.$store.getters['numberPages/getnumberOfPages']){
                let pageNumber = this.$store.getters['numberPages/getnumberOfPages']
                let changeNumberfilter = changeNumber.filter(function(value){
                    return value != pageNumber                    
                })
                this.numberOfСolumns = changeNumberfilter
                this.perPage = Number(pageNumber)
                return pageNumber
            } else{
              this.$store.dispatch("numberPages/numberOfPages");   
            } 
          },
          set(value){
            this.$store.dispatch("numberPages/changeNumberOfPages", {change: value})
          }
        },
        mainAcciunt: function(){
          let that = this
          if ((typeof this.$store.getters.getAccountStore) == "object"){
            this.totalRows = this.$store.getters.getAccountStore.length

                // в accountId сохраним  id всех оперций
            let accountId = []
            this.$store.getters.getAccountStore.forEach(function(item){
                if(!accountId.includes(item['AccountId'])){
                  accountId.push(item.AccountId)
                }
            })
            
                            // на основании id в accountId ищем счета и добавляем их название в accountOptions
            this.$store.getters.getAccountInfo.forEach(function(item){
                if(accountId.includes(item['ID']) && !that.accountOptions.includes(item['Name'])){
                  that.accountOptions.push(item.Name)
                }
            })
            this.accountInfoForSumm = this.$store.getters.getBalanceData   
            return this.$store.getters.getAccountStore
          }
        },
        getBills: function(){
          return this.$store.getters.getBillsB24foTable
        },
        getdateStart: function(){
          return this.$store.getters.getPeriodData.periodStart
        },
        getdateEnd: function(){
          return this.$store.getters.getPeriodData.periodEnd
        },
        getAdmin: function(){
          return this.$store.getters.getAdmin
        }
    },
    methods: {
        fillingTheTable: function(){
          this.items = this.$store.getters.getAccountStore;
          this.totalRows = this.items.length
        },
        textColor: function(value,key,item){
            if (item.Charge){
             return 'danger'
            } else {
              return 'success'
            }
        },
        plusMinus: function(value,key,item){
            if (item.Charge){
             return value = '- ' + item.Amount
            } else {
              return value = '+ ' + item.Amount
            }
        },
        userDate: function(value,key,item){
          // return value = item.TransactionDate
          return this.dateTransForSort(value)
        },
          // приводит дату к нужному виду
        dateTransForSort: function(date){
            let yearTransform = date.substr(2)
            let transform = yearTransform.substr(0,8).split('-')
            let one = transform.slice(0,1)
            let two = transform.slice(2)
            transform.splice(0, 1, two)
            transform.splice(2, 1, one)
            return transform.join('.')
        },
        dateTransformUpdate(date){
            let transform = date.substr(0,10).split('-')
            let one = transform.slice(0,1)
            let two = transform.slice(2)
            transform.splice(0, 1, two)
            transform.splice(2, 1, one)
            return transform.join('-')
        }, 
          // выводит название счета
        accountNameFromId: function(value,key,item){
          let section = this.$store.state.accountInfo
          section.forEach(function(items){
            if (items.ID == item.AccountId){
                value = items.Name
            }
          })   
          return value       
        },
          // фильтр
        filterOpt(row, val){
            let that = this
                  // фильтр по дате .setHours(0, 0, 0, 0)
            let storeDate = this.$store.getters.getPeriodData
            let periodStartStore = storeDate.periodStartStore.setHours(0, 0, 0, 0)
            let periodEndStore = storeDate.periodEndStore.setHours(0, 0, 0, 0)
            if (!this.date1 && !this.date2){
              return false;
            }
            let date1 = this.date1.setHours(0, 0, 0, 0)
            let date2 = this.date2.setHours(0, 0, 0, 0)
            if ((periodStartStore <= date1 && date1 <= periodEndStore) &&
                (periodStartStore <= date2 && date2 <= periodEndStore) && (date1 <= date2)){

              let dateValueStart = new Date(row.dataSort).setHours(0, 0, 0, 0)
              let dateValueEnd = new Date(row.dataSort).setHours(0, 0, 0, 0)         
              if ((dateValueStart < date1) || (dateValueEnd > date2)){
                
                return false
              }
            } else if (this.date1 > this.date2){
                let dateValueStart = new Date(row.dataSort.substr(0,10) + 'T16:59:59.000Z')
                let dateValueEnd = new Date(row.dataSort)   
                this.date1 = this.date2
                if (dateValueStart != this.date1){
                  return false
                } 
            }
            else {
              let periodStart = this.date1
              let periodEnd = this.date2
            }
                   // фильтр по номеру счета
            if(val.filterAccount != 'Все счета'){
              let idAccount
              this.$store.getters.getAccountInfo.forEach(function(items){
                  if(items['Name'] == val.filterAccount){
                    idAccount = items.ID
                  }
              })
              if (row.AccountId != idAccount){
                return  false
              } 
            }
                 // фильтр по приход/расход
            if ((val.filterDebet == 'Приход' && row.Charge != false) || 
                (row.Charge != false && !this.$store.getters.getAdmin) || 
                (val.filterDebet == 'Расход' && row.Charge != true)
            ) {
                return   false
            } 

          
             // фильтр по проведен/ не проведен
            if ((val.filterheld == 'Проведенные' && !(row.nameBill == true && row.Charge == false)) || 
                (val.filterheld == 'Не проведенные' && !(row.nameBill == undefined && row.Charge == false))
            ) {
                return   false
            } 

            return true

        },
          // пагинация зависит от фильтра
        onFiltered: function(filteredItems){
          this.totalRows = filteredItems.length
        },
          //наполняет массив счетов для связи и выводит модальное окно
        showBillsModal: function(value, bills, find){
          this.valueBills = value
          this.billsEnd = 2*value.Amount
          for (let i=0; i<bills.length; i++){
            if ((bills[i]['PRICE'] == value.Amount) && (find == 'find')){
              this.billtable = true
              // bills[i]['payerName'] = value.Payer.Name
              bills[i]['href'] = "https://"+obj.domain+"/crm/invoice/show/"+bills[i].ID+"/"
              bills[i]['payDate'] = value.TransactionDate
              this.arrBill.push(bills[i])
            }
            else if (find != 'find'){
              this.billtable = true
                // bills[i].payerName = value.Payer.Name
                bills[i]['href'] = "https://"+obj.domain+"/crm/invoice/show/"+bills[i].ID+"/"
                bills[i]['payDate'] = value.TransactionDate
                this.arrBill.push(bills[i])
            }
          }
          this.$refs.billsRef.show()
        },
          // закрывает модальное окно счетов для связи
        hideBillsModal: function(){
          this.arrBill = []
          this.billtable = false
          this.$refs.billsRef.hide()
        },
          // выводит модальное окно поиска счетов для связи
        billsFindModal: function(){
          this.arrBill = []
          this.billtable = false
          this.$refs.billsRef.hide()

          this.$refs.billsFindRef.show()
        },
          // закрывает модальное окно поиска счетов для связи
        hideBillsFindModal: function(){
          this.billsCheck = false
          this.$refs.billsFindRef.hide()
        },
          // поиск счетов по заданным параметрам
        billsFindforOne: function(){
          let that = this
          this.Alert = "Идет поиск"
          this.alertFlag = true
          let payed
          let filter = {}

          if (this.billsCheck){
            filter = {
              '>=DATE_BILL': this.date3,
              '<=DATE_BILL': this.date4,
              ">=PRICE": this.billsFrom,
              "<=PRICE": this.billsEnd
            }
          } else {
            filter = {
              'PAYED': 'N',
              '>=DATE_BILL': this.date3,
              '<=DATE_BILL': this.date4,
              ">=PRICE": this.billsFrom,
              "<=PRICE": this.billsEnd
            }
          }
          
          this.findBillsBX24(filter).then((response)=>{
            this.alertFlag = false
            that.$refs.billsFindRef.hide()
            that.showBillsModal(that.valueBills, response)
          })
        },
          // запрос счетов из Б24
        findBillsBX24: function(filter){
          let that = this

          return new Promise(function(resolve, reject) {
            let idInvoice = []
            findiDInvoice(filter)

              // находим все счета на основании фильтра
            function findiDInvoice(filter, next){
              axios
                .post("https://"+obj['domain']+"/rest/crm.invoice.list",{
                    "auth":obj['auth'],
                    'filter':filter,
                    "select": ["ID"],
                    "next": next
                    })
                .then(response => {
                  if (response.data.next){
                    for (let i=0;i<response.data.result.length;i++){
                      idInvoice.push(response.data.result[i].ID)
                    }
                    findiDInvoice(filter, response.data.next)
                  } else {
                    for (let j=0;j<response.data.result.length;j++){
                      idInvoice.push(response.data.result[j].ID)
                    }
                      findInvoice(idInvoice);
                  }
                })
                .catch(
                  function(error){
                    // let alert = "Ошибка, попробуйте уменьшить диапазон поиска"
                    // that.$store.dispatch('alertInfo', alert)
                    // that.$refs.billsFindRef.hide()
                    that.Alert = "Ошибка поиска, уменьшите период"
                    console.error(error)
                  }
                  // error => console.error(error)
                  );
            }
                  // подробная информация о счетах
            function findInvoice(arrId){
              let size = 50
              let invoice_extended = []
              let invoiceRang = []
              for (let i = 0; i <Math.ceil(arrId.length/size); i++){
                  invoiceRang[i] = arrId.slice((i*size), (i*size) + size);
              }
              batch(invoiceRang, 0)

              function batch(arr, rang){
                    if (arr[rang]){
                        let itemList = {}
                        for (let i=0;i<arr[rang].length;i++){
                            let nameBatchInvoice = 'invoice_'+i
                            itemList[nameBatchInvoice]=['crm.invoice.get', {
                                "id": arr[rang][i]
                            }]
                        }

                        BX24.callBatch(itemList, function(result){
                            for (let r in result){
                              invoice_extended.push(result[r].data())
                            }
                            batch(arr, rang+1)
                        })

                    } else {              
                        return resolve(invoice_extended)
                    }
                }
            }
          })
        },

          // по нажатию на кнопку связать связывает операцию со счетом и проводит его
        tieAccount: function(event){
          let id = event.target.getAttribute('data') // id счета из Б24
          let date = this.dateTransformUpdate(event.target.getAttribute('data-date')) // дата выставления счета из Б24
          let valueBills = this.valueBills // данные об операции

          this.$store.dispatch("accountTie", {id, date, valueBills})
          this.$refs.billsRef.hide()
        },
          // пересчитывает конечный остаток на основании фильтра, без запроса в базу
        balancee: function(value, num){
          let account = this.$store.getters.getAccountStore // все операции в таблице
          let storeDate = this.$store.getters.getPeriodData // дата из хранилища
          
          let accountMinus = 0
          let periodStartStore = storeDate.periodStartStore.setHours(0, 0, 0, 0)
          let periodEndStore = storeDate.periodEndStore.setHours(0, 0, 0, 0)
          
          let valueS = value.setHours(0, 0, 0, 0)
          let valueNow
          if (this.valueNow && num == 'end'){
            valueNow = this.valueNow.setHours(0, 0, 0, 0)
          }  else if (num == 'end' && !this.valueNow){
            valueNow = periodEndStore
          } 
          // debugger
            // если конечная дата (num == 'end') в диапазоне загруженной базе, идет пересчет конечного остатка
          if (periodStartStore <= valueS && valueS <= periodEndStore && num == 'end'){
            if (valueNow == '' || valueNow > valueS){
                for (var j=0; j<this.accountInfoForSumm.length; j++){
                  for(var i=0; i< account.length;i++){
                    let date = new Date(account[i].dataSort).setHours(0, 0, 0, 0)
                    if (date > valueS && account[i].AccountId == this.accountInfoForSumm[j].id[0] && date <= valueNow){
                      if (account[i].Charge){
                        accountMinus = Number(accountMinus) - Number(account[i].Amount)
                      } else {
                        accountMinus = Number(accountMinus) + Number(account[i].Amount)
                      }    
                    }
                  }
                      this.accountInfoForSumm[j].Summ = Number(this.accountInfoForSumm[j].Summ) - accountMinus
                      this.accountInfoForSumm[j].Summ = this.accountInfoForSumm[j].Summ.toFixed(2)
                  accountMinus = 0
                }
              } else if (valueNow < valueS && num == 'end'){
                for (var j=0; j<this.accountInfoForSumm.length; j++){
                  for(var i=0; i< account.length;i++){
                    let date = new Date(account[i].dataSort).setHours(0, 0, 0, 0)
                    if (date <= valueS && account[i].AccountId == this.accountInfoForSumm[j].id[0] && date > valueNow){
                      if (account[i].Charge){
                        accountMinus = Number(accountMinus) - Number(account[i].Amount)
                      } else {
                        accountMinus = Number(accountMinus) + Number(account[i].Amount)
                      }    
                    }
                  }
                      this.accountInfoForSumm[j].Summ = Number(this.accountInfoForSumm[j].Summ) + accountMinus
                      this.accountInfoForSumm[j].Summ = this.accountInfoForSumm[j].Summ.toFixed(2)
                  accountMinus = 0
                }
              } 
              else if (valueNow == valueS){
                this.accountInfoForSumm = this.accountInfoForSumm
              }
              this.valueNow = value
          } else if (periodStartStore > valueS && valueS < periodEndStore){
               let startDate, endDate
               if (num == 'start'){
                 startDate = value
                 endDate = storeDate.periodEndStore
               } else {
                 startDate = storeDate.periodStartStore
                 endDate = value
               }
               let periodData = {
                  "periodStartStore": startDate,
                  "periodEndStore":  endDate,
              }
               this.$store.commit('loadPeriodData', periodData)
              //  this.$store.dispatch("accounsInformation")
               this.$store.dispatch('bitrixTrans', 'load')
                // this.$store.dispatch("periodform", {periodStart: startDate, periodEnd:endDate})
             }
        },
        dateUpdate(date){
          if (date<10){
            return '0'+date
          } else return date
        }
    },
    mounted(){
      let that = this
          // является ли пользователь админом
      BX24.init(function(){
          if (BX24.isAdmin()){
            that.$store.commit("isAdmin", true)
          }
          
      })
      

      let Data = new Date()
      let periodStart = new Date(Data.setDate(Data.getDate() - 5)) 
      let periodEnd = new Date()

      let Data2 = new Date()
      let dateStartForInvoice = new Date(Data2.setMonth(Data2.getMonth() - 1))

        // фильтр для запроса счетов
      let filter = {
        'PAYED': 'N',
        '>=DATE_BILL': `${dateStartForInvoice.getFullYear()}-${this.dateUpdate(dateStartForInvoice.getMonth())}-${this.dateUpdate(dateStartForInvoice.getDate())}`
      }

      this.date1 = periodStart
      this.date2 = periodEnd
      this.date3 = `${dateStartForInvoice.getFullYear()}-${this.dateUpdate(dateStartForInvoice.getMonth())}-${this.dateUpdate(dateStartForInvoice.getDate())}`// dateStartForInvoice,
      this.date4 = `${periodEnd.getFullYear()}-${this.dateUpdate(periodEnd.getMonth())}-${this.dateUpdate(periodEnd.getDate())}` // periodEnd,

      // this.$store.dispatch("numberOfPages") 

      this.findBillsBX24(filter).then((response)=>{
          // вызывает action для считывания счетов с Б24
        that.$store.dispatch("addBillsB24", response)
      })

      
      let periodData = {
          "periodStartStore": periodStart,
          "periodEndStore": periodEnd
      }

      this.$store.commit('loadPeriodData', periodData)
      this.$store.dispatch("accounsInformation")

        // вызывает action для изменениея даты
      // this.$store.dispatch("periodform",{periodStart, periodEnd})

      
         
    },
    components: {
        Datepicker
    }
}
</script>
<style>
  .danger {
    color:red; 
  }
  .success{
    color:green;
  }
  li {
    list-style-type: none;
  }
  .datepicker {
    border-radius: 5px;

  }
  .datepickerfilt{
    width: 130px;
    height: 38px;
    border: 1px solid #DCDCDC;
    border-radius: 3px;
    padding-left: 12px;
  }
  p.serachAlert{
    color:red; 
    text-align:center; 
    margin: 0 auto;
    font-weight: 700;
    border-radius: 5px;
  }
  .checkBill{
    cursor: pointer;
  }
  .checkBill:hover{
    color: #dc3545;
    text-decoration: underline;
  }
</style>