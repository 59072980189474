var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  staticClass: "mb-1",
                  attrs: { variant: "success" },
                  on: { click: _vm.bitrixDelete }
                },
                [_vm._v("Удалить хранилища Битрикса")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-1",
                  attrs: {
                    horizontal: "",
                    label: "Кол-во операций на странице"
                  }
                },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { options: _vm.numberOfСolumns },
                      on: { input: _vm.changeNumberofPage },
                      model: {
                        value: _vm.perPage,
                        callback: function($$v) {
                          _vm.perPage = $$v
                        },
                        expression: "perPage"
                      }
                    },
                    [
                      _c("template", { slot: "first" }, [
                        _c("option", { domProps: { value: _vm.perPage } }, [
                          _vm._v(_vm._s(_vm.getPages))
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }