import axios from 'axios'

export  default {
    actions:{
        // функиция помечает счет как проведенный
        accountTie: function({dispatch}, {id, date, valueBills}){
            let addName // имя проведенного счета в операции
            let href  // ссылка на  проведенный счет в операцию
                // получаем поля счета
            axios
                .post("https://"+obj['domain']+"/rest/crm.invoice.get",{
                    'auth':obj['auth'],
                    'id':id
                    })
                .then(response => {
                        let fields = response.data.result
                        addName = fields.ORDER_TOPIC
                        href = "https://"+obj.domain+"/crm/invoice/show/"+fields['ID']+"/"
                        fields['STATUS_ID'] = 'P'
                        fields['PAY_VOUCHER_DATE'] = date

                            // изменяем статус счета на Оплаченный
                        axios
                            .post("https://"+obj['domain']+"/rest/crm.invoice.update",{
                                'auth':obj['auth'],
                                'id':id,
                                'fields': fields
                                })
                            .then(response => {
                                    bitrixEntityGet()
                                })
                            .catch(error => {
                                let alert = 'Счет не провелся'
                                dispatch('alertInfo', alert, {root: true})
                                console.error(error)
                            });  
                    })
                .catch(error => {
                    let alert = 'Ошибка запроса сета Б24'
                    dispatch('alertInfo', alert, {root: true})
                    console.error(error)
                });  

                 // получаем из хранилища б24 все операции из раздела (счета) за указанный период
            function bitrixEntityGet(){
                axios
                    .post("https://"+obj['domain']+"/rest/entity.item.get",{
                        'auth':obj['auth'],
                        'ENTITY': 'store',
                        'filter': {
                            'NAME': valueBills.TransactionUID
                        }
                        })
                    .then(response => {
                            let item = response.data.result
                            accountChange(item[0].ID, item[0].DETAIL_TEXT)
                              
                        })
                    .catch(error => {
                        let alert = 'Ошибка запроса сета Б24'
                        dispatch('alertInfo', alert, {root: true})
                        console.error(error)
                    });  
            } 
                // добавляет данные о счете в операции
            function accountChange(id, text){
                let newText = JSON.parse(text)
                newText['nameBill'] = addName
                newText['hrefBill'] = href
                
                axios
                    .post("https://"+obj['domain']+"/rest/entity.item.update",{
                        'auth':obj['auth'],
                        'ENTITY': 'store',
                        'id':id,
                        'DETAIL_TEXT': JSON.stringify(newText)
                        })
                    .then(response => {
                        dispatch('bitrixTrans', 'load', {root: true})
                        })
                    .catch(error =>{
                        let alert = 'Ошибка обновления операции'
                        dispatch('alertInfo', alert, {root: true})
                        console.error(error)
                        });  
            }
        },
    }
}