var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h3", [_vm._v("Описание приложения Фин24:")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Приложение предназначено для получения операций по расчётным счетам и связывания поступлений со счетами Битрикс24.\n        Приложение может обрабатывать выгрузку из 1С либо получать информацию из сервиса Seeneco.com.\n        Вся информация представлена в виде удобной таблицы. Информацию можно фильтровать по основным критериям и сортировать как по убыванию, так и по возрастанию.\n        В настройках счёта необходимо указать начальный остаток, программа автоматически выведет конечный остаток на выбранную дату.\n        Все входящие поступления можно связать с выставленным счётом в Битрикс24, в результате счёт будет помечен как оплаченный, и у операции будет стоять ссылка на него.\n    "
        )
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("Порядок и рекомендации:")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Для начала работы нужно создать счёт во вкладке «Счета», где нужно заполнить все поля. Для интеграции с seeneco нужно ввести ключ.\n        Если все поля заполнены верно, на вкладке «выписка» появятся операции по заведённому счёту за последние пять дней\n        Конечный остаток формируется на основе уже загруженных в базу программы данных, по этому, если задать начальный остаток на дату, которая раньше когда-либо загруженной информации по счетам — конечный остаток будет некорректен.\n        Удаление счёта может занять некоторое время, и возможно понадобится неоднократно нажать на крестик, это связано с количеством загруженных операций по счёту — чем больше операций, тем дольше их удаление.\n    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }