<template>
      <div>
         <b-nav class="nav_menu" pills>
          <b-nav-item to="/" active-class="active" exact>Выписка</b-nav-item>
          <b-nav-item to="/accounts" active-class="active">Счета</b-nav-item>
          <b-nav-item to="/options" active-class="active">Настройки</b-nav-item>
          <b-nav-item to="/about" active-class="active">О приложении</b-nav-item>
          <b-nav-item to="/support" active-class="active">Написать разработчику</b-nav-item>
        </b-nav>
        <alert></alert>
        <router-view></router-view>
      </div>

      <!-- <b-card>
        <b-tabs pills card>
          <b-tab title="Выписка" active>
            <alert></alert>
            <tableBootstrap ></tableBootstrap>
          </b-tab>
          <b-tab title="Счета">
            <alert></alert>
            <accountInformation></accountInformation>
          </b-tab>
          <b-tab title="Настройки">
            <alert></alert>
            <options></options>
          </b-tab>
          <b-tab title="О приложении">
            <aboutattachment></aboutattachment>
          </b-tab>
          <b-tab title="Написать разработчику">
            <alert></alert>
            <support></support>
          </b-tab>
        </b-tabs>
      </b-card> -->

</template>
 
<script>
// import accountInformation from './accountInformation.vue'
// import tableBootstrap from './table_bootstrap.vue'
// import options from './options.vue'
// import aboutattachment from './aboutattachment.vue'
import alert from './alert.vue'
// import support from './support.vue'


export default {
  name: 'App',
  data () {
    return {
    }
  },
  methods: {},
  components: {
    // accountInformation,
    // tableBootstrap,
    // options,
    // aboutattachment,
    alert,
    // support
  }
}
</script>
 
<style scoped>
.nav_menu{
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: #b3d8fd;
}
</style>