var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        {
          attrs: {
            variant: "success",
            dismissible: "",
            show: _vm.showDismissibleSuccess
          },
          on: {
            dismissed: function($event) {
              _vm.showDismissibleSuccess = 0
            },
            "dismiss-count-down": _vm.countDownChanged
          }
        },
        [_vm._v(_vm._s(_vm.succesInfo) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "b-alert",
        {
          attrs: { variant: "danger", show: _vm.showDismissibleAlert },
          on: {
            dismissed: function($event) {
              _vm.showDismissibleAlert = 0
            },
            "dismiss-count-down": _vm.countDownChangedError
          }
        },
        [_vm._v(_vm._s(_vm.alertInfo) + "\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }