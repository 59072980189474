import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from '../components/App.vue'
import store from '../store/store.js'
import router from '../router/index.js'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue);

  new Vue({
    store,
    router,
    el: '#app',
    render: h => h(App)
  })

