<template>
    <div>
        <h3>Описание приложения Фин24:</h3>
        <p>Приложение предназначено для получения операций по расчётным счетам и связывания поступлений со счетами Битрикс24.
            Приложение может обрабатывать выгрузку из 1С либо получать информацию из сервиса Seeneco.com.
            Вся информация представлена в виде удобной таблицы. Информацию можно фильтровать по основным критериям и сортировать как по убыванию, так и по возрастанию.
            В настройках счёта необходимо указать начальный остаток, программа автоматически выведет конечный остаток на выбранную дату.
            Все входящие поступления можно связать с выставленным счётом в Битрикс24, в результате счёт будет помечен как оплаченный, и у операции будет стоять ссылка на него.
        </p>

        <h3>Порядок и рекомендации:</h3>
        <p>Для начала работы нужно создать счёт во вкладке «Счета», где нужно заполнить все поля. Для интеграции с seeneco нужно ввести ключ.
            Если все поля заполнены верно, на вкладке «выписка» появятся операции по заведённому счёту за последние пять дней
            Конечный остаток формируется на основе уже загруженных в базу программы данных, по этому, если задать начальный остаток на дату, которая раньше когда-либо загруженной информации по счетам — конечный остаток будет некорректен.
            Удаление счёта может занять некоторое время, и возможно понадобится неоднократно нажать на крестик, это связано с количеством загруженных операций по счёту — чем больше операций, тем дольше их удаление.
        </p>
    </div>

</template>

<script>
export default {

}
</script>

<style>

</style>
