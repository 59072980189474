import axios from 'axios'

export default {
    namespaced: true,
    state:{
        options: {
            pages: '', // число операций на странице
            id: ''
        },
    },
    getters:{
        getnumberOfPages(state){
            return state.options.pages;
        },
    },
    mutations:{
        numberOfPages(state, payload){
            state.options.pages = payload
        },
        idItemOptions(state, payload){
            state.options.id = payload
        },
    },
    actions:{
        numberOfPages: function({commit}){

            axios
                .get("https://"+obj['domain']+"/rest/entity.get?auth="+obj['auth'])
                .then(responce => {
                    let store = responce.data.result
                    let storeflag = false
                    for (var i=0;i<store.length; i++){
                        if (store[i]['ENTITY'] == "settings"){
                            storeflag = true
                            break
                        }
                    }
                    if (storeflag) {
                        numberOfPageGet()
                    } else createStoreSettings()
                })
                .catch(error => {
                    let alert = 'Ошибка проверки хранилища'
                    dispatch('alertInfo', alert, {root: true})
                    console.log(error)
                }); 
            
                // создаение хранилища Settings
            function createStoreSettings(){
                axios
                    // .get("https://"+obj['domain']+"/rest/entity.add?auth="+obj['auth']+"&ENTITY=settings&NAME=settings&ACCESS=[AU=W]")
                    .post("https://"+obj['domain']+"/rest/entity.add",{
                        "auth": obj['auth'],
                        "entity": "settings",
                        "NAME":"settings",
                        "access":{
                            AU: "W"
                        }
                    })
                    .then(responce => {
                        axios
                            .get("https://"+obj['domain']+"/rest/entity.item.add?auth="+obj['auth']+"&ENTITY=settings&NAME=options")
                            .then(responce => {
                                numberOfPageGet()
                            })
                            .catch(error => {
                                let alert = 'Ошибка создания элемента хранилища'
                                dispatch('alertInfo', alert, {root: true})
                                console.log(error)
                            }); 
                    })
                    .catch(error => {
                        let alert = 'Ошибка создания хранилища'
                        dispatch('alertInfo', alert, {root: true})
                        console.log(error)
                    }); 
            }

            function numberOfPageGet(){
                let id
                let quantity
                axios
                    .get("https://"+obj['domain']+"/rest/entity.item.get?ENTITY=settings&auth="+obj['auth'])
                    .then(response =>{
                        id = response.data.result[0]['ID']
                        if(response.data.result[0]['PROPERTY_VALUES']){
                            quantity = response.data.result[0]['PROPERTY_VALUES']['numberOfPages']
                        } else {
                            quantity = 10  
                            axios
                                .get("https://"+obj['domain']+"/rest/entity.item.property.add?ENTITY=settings&NAME=numberOfPages&PROPERTY=numberOfPages&TYPE=S&auth="+obj['auth'])
                                .then(response =>{          
                                    axios
                                        .get("https://"+obj['domain']+"/rest/entity.item.update?ID="+id+"&ENTITY=settings&PROPERTY_VALUES%5BnumberOfPages%5D="+quantity+"&auth="+obj['auth'])
                                        .then(response =>{
                                            console.log('ok')
                                        })
                                        
                                        .catch(error => {
                                                let alert = 'Ошибка изменения отображения количества операций на странице'
                                                dispatch('alertInfo', alert, {root: true})
                                                console.log(error)
                                            })
                                        })
                                .catch(error => {
                                    let alert = 'Ошибка добавления свойства в настройки'
                                    dispatch('alertInfo', alert, {root: true})
                                    console.log(error)
                                })
                        }
                        commit("numberOfPages",quantity)
                        commit("idItemOptions",id)
                    })
                    .catch(error => {
                        let alert = 'Ошибка вызова хринилища (счета)'
                        dispatch('alertInfo', alert, { root: true })
                        console.log(error)
                    })
                }    
        },
            // изменяет количество оперций на странице
        changeNumberOfPages: function({state, commit}, change){
            let id = state.options.id
            axios
                .get("https://"+obj['domain']+"/rest/entity.item.update?ID="+id+"&ENTITY=settings&PROPERTY_VALUES%5BnumberOfPages%5D="+change+"&auth="+obj['auth'])
                .then(response =>{
                    commit("numberOfPages",change)
                })
                .catch(error => {
                    let alert = 'Ошибка изменения отображения количества операций на странице'
                    dispatch('alertInfo', alert, {root: true})
                    console.log(error)
                })
        },
    }
}