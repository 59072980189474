var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-nav",
        { staticClass: "nav_menu", attrs: { pills: "" } },
        [
          _c(
            "b-nav-item",
            { attrs: { to: "/", "active-class": "active", exact: "" } },
            [_vm._v("Выписка")]
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { attrs: { to: "/accounts", "active-class": "active" } },
            [_vm._v("Счета")]
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { attrs: { to: "/options", "active-class": "active" } },
            [_vm._v("Настройки")]
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { attrs: { to: "/about", "active-class": "active" } },
            [_vm._v("О приложении")]
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { attrs: { to: "/support", "active-class": "active" } },
            [_vm._v("Написать разработчику")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("alert"),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }