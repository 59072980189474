import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import table_bootstrap from "../components/table_bootstrap.vue"
import accountInformation from '../components/accountInformation.vue'
import options from '../components/options.vue'
import aboutattachment from '../components/aboutattachment.vue'
import support from '../components/support.vue'

export default new Router({
    mode: 'history',
    routes: [
        // {
        //     path: '*',
        //     redirect: '/home'
        // },
        {
            path: '/',
            component: table_bootstrap
        },
        {
            path: '/accounts',
            component: accountInformation
        },
        {
            path: '/options',
            component: options
        },
        {
            path: '/about',
            component: aboutattachment
        },
        {
            path: '/support',
            component: support
        },
    ]
})